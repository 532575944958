import React from "react"

import Layout from "../../components/Layout"

import { Link } from "gatsby"

// import platform from "../../images/origens/platform.png";
import map from "../../images/origens/map.png";

export default function Home() {
  return (
    <Layout breadcrumbs={["Avina Collect"]}>
      <div id="heading5" >
        <h1>Avina Collect</h1>
      </div>

      {/* Main */}
      <section id="main" className="wrapper">
        <div className="inner">

          <div className="content page">

            {<h5><Link to="/">Inicio</Link> &gt;&gt; Projetos  &gt;&gt; Avina Collect</h5>}

            <header>
              <h2 className="Avina">Coleta de dados georreferenciada, offline e fácil</h2>
            </header>
                <p className="headline">O mundo é complicado, mas a coleta de dados não precisa ser!</p>
                      
                <p>O Coletor, desenvolvido para a Fundação Avina, é uma aplicação mobile (Android ou IOS) para a coleta de dados 
                  georreferenciados, em cenários onde a conexão com a internet é inexistente ou instável.</p>
                <p>Os formulários inteligentes são facilmente elaborados e preenchidos pelos usuários e administradores do App. 
                  Os dados de campo podem ser coletados offline, e, posteriormente, enviados ao servidor. 
                  Após o upload, é possível gerar um relatório automático de visualização e análise,  com tabelas, gráficos e mapas, 
                  no app ou no navegador.</p>
                <p>O app conta com a parceria da Ambev e do Instituto Agir Ambiental e será utilizado pela Fundação Avina, 
                  especialmente em projetos relacionados ao acesso à água, em comunidades da região amazônica e do semiárido nordestino.</p>
                <br />
                <ul>
                <li>Acesse o Coletor em: <a href="https://avinacollect.avina.net/" target="_blank">avinacollect.avina.net</a></li>
                </ul>

            </div>
            </div>
        </section>
    </Layout>
  )
}
